import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const MainWrapper = styled.div`
margin: 0;
`

const ReactDeveloper = () => (
    <MainWrapper>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> React Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top React Developers. Top
                                    companies and start-ups choose Codestaff React professionals
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top React Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE REACT DEVELOPERS' banner='/react-developer.png' bannerAlt='React banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default ReactDeveloper